import { createContext } from "react";
const initialstate = {
  que1: "",
  que2: "",
  que3: "",
  que4: "",
};
const SolutionsContext = createContext(initialstate);
SolutionsContext.displayName = "SolutionsContext";

export default SolutionsContext;
